import React, {useEffect} from "react"
import { useTranslation } from "react-i18next"

import './software.component.less';
import OpenData from "../../assets/img/data-analysis/img/open_data.png"
import Standalone from "../../assets/img/data-analysis/img/standalone.jpg"
import DataAnalysis from "../../assets/img/data-analysis/img/data_analysis.png"
import SoftwarePoster from "../../assets/img/monitoring-networks/img/software-poster.jpg"
import Software from "../../assets/video/monitoring-networks/software.mp4"
import SoftwareImage from "../../assets/img/software/software-image.png"

import {useI18next} from "gatsby-plugin-react-i18next";
import SEO from "../seo";

const SoftwareComponent = () => {
    const { t, i18n } = useTranslation()
    const { originalPath } = useI18next()

    return (
        <div>
            <SEO description={t("metaDescriptionSoftware")} lang={i18n.language} title={t("titleSoftware")} link={"https://cityair.io/"+ i18n.language + originalPath} />
            <div className="wrapper_container_main align_center_ipad wrapper_container_main-flex">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-6 col-fhd-6 col-rt-6 flex_box_col flex_box_align_ipad">
                            <div className="color_purple data_analysis_main_block__title">{t("softwareSubtitle")}</div>
                            <div className="color_black data_analysis_main_block__sub_title ">{t("dataHandling")}</div>
                            <div className="color_black_light data_analysis_main_block__description">{t("aSetOfSoftware")}</div>
                            <div className="platform_block__info-important data_analysis_main_block__info-important">
                                Программное обеспечение включено в <a target="_blank" href="https://reestr.digital.gov.ru/reestr/309010/" className="platform_block__info-important__link">Единый реестр российских программ</a> для ЭВМ и БД​    
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-6 col-fhd-6 col-rt-6" />
                    </div>
                </div>
                <div className="wrapper_img_data_analysis">
                    <img alt="" src={DataAnalysis} className="img_data_analysis" />
                </div>
            </div>

            <div className="wrapper_container">
                <div className="container color_gray data_analysis_block_1__container">{t("threeDataAccess")}</div>
            </div>

            <div className="wrapper_container">
                <div className="data_analysis_block_2__container">
                    <div className="container">
                        <div className="row">
                            <div className="col-0 col-sm-0 col-md-0 col-lg-7 col-xl-7 col-xga-7 col-fhd-7 col-rt-7" />
                            <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5">
                                <div className="color_blue data_analysis_block__number">01.</div>
                                <div className="data_analysis_block__title">{t("openDataFor")}</div>
                                <div className="video-block video-block_position-relative video-block_display-mobile">
                                    <img alt="" src={OpenData} className="img_data_software" />
                                </div>
                                <div className="data_analysis_block__list color_black_light">
                                    <ol>
                                        <li className="content_container__list-li">Карта и&nbsp;графики онлайн</li>
                                        <li className="content_container__list-li">Кастомизация и&nbsp;брендирование</li>
                                        <li className="content_container__list-li">Ограниченная информация о&nbsp;концентрации загрязнителей и&nbsp;качестве воздуха в&nbsp;терминах AQI (индекса качества воздуха)</li>
                                        <li className="content_container__list-li">Инструменты для сравнения данных о&nbsp;качестве воздуха в&nbsp;различных точках на&nbsp;карте</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="img_block data-analysis__img_block img_block-left video-block_display-desktop">
                        <img alt="" src={OpenData} className="img_data_analysis_open_data img_data_analysis_open_data-en" />
                    </div>
                </div>
            </div>

            <div className="wrapper_container">
                <div className="data_analysis_block_2__container">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5">
                                <div className="color_blue data_analysis_block__number">02.</div>
                                <div className="data_analysis_block__title">Внутренние сервисы для&nbsp;специалистов на&nbsp;сервере CityAir</div>
                                <div className="video-block video-block_position-relative video-block_display-mobile">
                                    <img alt="" src={SoftwareImage} className="img_data_software" />
                                </div>
                                <div className="data_analysis_block__list color_black_light">
                                    <ol>
                                        <li className="content_container__list-li ">Формирование отчетов</li>
                                        <li className="content_container__list-li">Экспорт данных по&nbsp;API и&nbsp;в&nbsp;Excel</li>
                                        <li className="content_container__list-li">Оповещения по маркерам отклонений</li>
                                        <li className="content_container__list-li">Анализ эффективности сети</li>
                                        <li className="content_container__list-li">Расчет прогноза и&nbsp;визуализация распространения загрязняющих веществ</li>
                                    </ol>
                                </div>
                            </div>
                            <div className="col-0 col-sm-0 col-md-0 col-lg-7 col-xl-7 col-xga-7 col-fhd-7 col-rt-7" />
                        </div>
                    </div>
                    <div className="video-block video-block_position-absolute video-block_position-right video-block_display-desktop">
                        <img alt="" src={SoftwareImage} className="img_data_software" />
                    </div>
                </div>
            </div>

            <div className="wrapper_container">
                <div className="data_analysis_block_2__container">
                    <div className="container">
                        <div className="row">
                            <div className="col-0 col-sm-0 col-md-0 col-lg-7 col-xl-7 col-xga-7 col-fhd-7 col-rt-7" />
                            <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5">
                                <div className="color_blue data_analysis_block__number">03.</div>
                                <div className="data_analysis_block__title">{t("standaloneSolution")}</div>
                                <div className="data_analysis_block__list color_black_light">
                                    <ol>
                                        <li className="content_container__list-li">{t("servicesForAccepting")}</li>
                                        <li className="content_container__list-li">{t("dataAnalysisAnd")}</li>
                                        <li className="content_container__list-li">{t("possibilityOf")}</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="img_block img_block-left_4-vw">
                        <img alt="" src={Standalone} className="img_data_analysis_standalone" />
                    </div>
                </div>
            </div>
        </div>
    )
}

SoftwareComponent.propTypes = {}

SoftwareComponent.defaultProps = {}

export default SoftwareComponent
